.embeddedDashboard {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: visible;

  iframe {
    width: 100%;
    height: 110vh;
    border: none;
    overflow: visible;
    display: block;
  }
}
