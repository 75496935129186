.avatarSkeleton {
    position: relative;
    background-color: rgba(224, 224, 224, 0.2) !important;

    > svg {
        position: absolute;
        bottom: -2px;
        width: 20px !important;
        height: 26px !important;
        transform: scale(1.4) !important;
        color: rgb(71, 96, 203) !important;
    }
}

.titleFontSize {
    font-size: 13px !important;
}