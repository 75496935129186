:root {
	--primary: #4760cb;
	--primaryBorderFaded: rgba(71, 96, 203, 0.4);
	--text: #151d34e5;
	--secondaryText: #151d34a6;
	--success: #4eae6f;
	--error: #f3584d;
	--error-faded: rgba(243, 88, 77, 0.05);
	--input-error: rgba(243, 88, 77, 0.05);

	--errorFaded: rgba(243, 88, 77, 0.05);
	--warning: rgba(233, 123, 45, 1);
	--whiteBase: #fff;
	--disabled: rgba(224, 224, 224, 1);
	--svg-white: rgba(255, 255, 255, 1);
	--grayTableBorder: rgba(224, 224, 224, 1);
	--light-gray: rgba(224, 224, 224, 0.4);
	--inactiveButton: #c5c5c5;
	--gray: rgba(21, 29, 52, 0.24);
	--gray-icon: rgba(21, 29, 52, 0.4);
	--tab-underline: rgba(233, 123, 45, 1);
	--text-faded: rgba(21, 29, 52, 0.65);
	--bg-table: rgba(224, 224, 224, 0.2);
	--aside-width: 55px;
	--bg-subsider-hover: rgb(240, 240, 240);
}
