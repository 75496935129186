.ant-btn {
	display: flex;
	align-items: center;
	justify-content: center;
}

button > span > svg {
	width: 16px;
	height: 16px;
}

button:disabled > span > svg {
	color: var(--disabled);
}

button.ant-btn-primary > span > svg {
	color: var(--svg-white);
}
