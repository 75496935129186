.detailFooter {
	position: fixed;
	bottom: 0;
	right: 0;
	margin-left: -24px;
	border-top: 1px solid var(--grayTableBorder);
	background: var(--whiteBase);
	padding: 12px 24px;
	width: calc(100% - var(--aside-width));
	transition: width 0.3s ease;
	overflow: scroll;
}
