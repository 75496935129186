.ant-pagination-item > a {
	font-size: 12px;
	font-weight: 700;
	color: var(--primary) !important;
}

.ant-pagination-item-active > a {
	color: var(--whiteBase) !important;
}

.ant-pagination-total-text {
	font-size: 12px;
	font-weight: 700;
	color: var(--primary);
}

.ant-pagination-prev,
.ant-pagination-prev > .ant-pagination-item-link,
.ant-pagination-next > .ant-pagination-item-link,
.ant-pagination-next {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	min-width: unset !important;
	min-height: unset !important;
	margin: 0 !important;
	width: 24px !important;
	height: 24px !important;
	button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.ant-pagination-prev {
	span {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS43MDcxMSAwLjI5Mjg5M0M2LjA5NzYzIDAuNjgzNDE3IDYuMDk3NjMgMS4zMTY1OCA1LjcwNzExIDEuNzA3MTFMMi40MTQyMSA1TDUuNzA3MTEgOC4yOTI4OUM2LjA5NzYzIDguNjgzNDIgNi4wOTc2MyA5LjMxNjU4IDUuNzA3MTEgOS43MDcxMUM1LjMxNjU4IDEwLjA5NzYgNC42ODM0MiAxMC4wOTc2IDQuMjkyODkgOS43MDcxMUwwLjI5Mjg5MyA1LjcwNzExQy0wLjA5NzYzMTEgNS4zMTY1OCAtMC4wOTc2MzExIDQuNjgzNDIgMC4yOTI4OTMgNC4yOTI4OUw0LjI5Mjg5IDAuMjkyODkzQzQuNjgzNDIgLTAuMDk3NjMxMSA1LjMxNjU4IC0wLjA5NzYzMTEgNS43MDcxMSAwLjI5Mjg5M1oiIGZpbGw9IiM0NzYwQ0IiLz4KPC9zdmc+Cg==');
		background-repeat: no-repeat;
		width: 6px;
		height: 10px;
	}
	svg {
		display: none;
	}
}
.ant-pagination-next {
	span {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yOTI4OTMgMC4yOTI4OTNDMC42ODM0MTcgLTAuMDk3NjMxMSAxLjMxNjU4IC0wLjA5NzYzMTEgMS43MDcxMSAwLjI5Mjg5M0w1LjcwNzExIDQuMjkyODlDNi4wOTc2MyA0LjY4MzQyIDYuMDk3NjMgNS4zMTY1OCA1LjcwNzExIDUuNzA3MTFMMS43MDcxMSA5LjcwNzExQzEuMzE2NTggMTAuMDk3NiAwLjY4MzQxNyAxMC4wOTc2IDAuMjkyODkzIDkuNzA3MTFDLTAuMDk3NjMxMSA5LjMxNjU4IC0wLjA5NzYzMTEgOC42ODM0MiAwLjI5Mjg5MyA4LjI5Mjg5TDMuNTg1NzkgNUwwLjI5Mjg5MyAxLjcwNzExQy0wLjA5NzYzMTEgMS4zMTY1OCAtMC4wOTc2MzExIDAuNjgzNDE3IDAuMjkyODkzIDAuMjkyODkzWiIgZmlsbD0iIzQ3NjBDQiIvPgo8L3N2Zz4K');
		background-repeat: no-repeat;
		width: 6px;
		height: 10px;
	}
	svg {
		display: none;
	}
}

.ant-pagination {
	.ant-select-arrow {
		margin-top: -4px !important;
		width: 10px;
		height: 6px;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976314 9.31658 -0.0976314 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976313 1.31658 -0.0976312 0.683417 0.292893 0.292893Z" fill="%234760CB"/></svg>');
		.ant-select-suffix {
			display: none;
		}

		&:hover {
			color: var(--primary);
		}
	}
}
.ant-table-pagination {
	display: flex;
	align-items: center;
	column-gap: 8px;
	margin: 0;
	.ant-pagination-item {
		width: 22px;
		height: 24px;
		min-width: unset;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}
}
