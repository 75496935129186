.title {
  flex: 1;
  text-align: center;
  height: 40px;

  border-bottom: 1px solid var(--disabled);

  color: var(--text-faded);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
}

.column {
  flex: 1;

  position: relative;

  border-bottom: 1px solid var(--disabled);
  background: var(--bg-table);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: var(--disabled);
  }
}

.item {
  padding: 10px 12px;

  border-radius: 6px;
  border: 1px solid var(--disabled);
  background: var(--whiteBase);

  color: var(--text);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 15.6px */
}

.itemTitle {
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}