.transactionsContainer {
	border-bottom: 1px solid var(--grayTableBorder);
	padding: 10px 4px;
}

.transactionsContainer:hover {
	background: var(--bg-table);
}

.transactionStyle {
	flex: 4;
}

.dateTitleStyle {
	color: var(--text-faded);
}

.DropdownStyle {
	cursor: pointer;
	padding: 2px;
}