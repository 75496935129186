.ant-breadcrumb {
	color: var(--text-faded);
	font-weight: 500;
	padding-left: 4px !important;
}

.ant-breadcrumb-separator {
	margin-inline: 6px !important;
}

.ant-breadcrumb-link > a {
	padding: 0 !important;
	font-weight: 700;
	color: var(--primary);
	&:hover {
		background: transparent !important;
	}
}
