.addFilterButton {
	max-width: fit-content;
	align-self: flex-start;
	max-height: 28px;
}

.filterButton {
	color: var(--text-faded) !important;
	font-weight: 500;
	border-color: var(--gray-icon);
	display: flex;
	align-items: center;
	padding: 6px 8px;
	max-height: 28px;

	&:hover {
		color: var(--text-faded) !important;
		border-color: var(--tab-underline) !important;
	}
}

.filterButtonActive {
	color: var(--text) !important;
	border-color: var(--tab-underline) !important;
}

.dropdownContainer {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 8px;
	row-gap: 8px;
	border: 1px solid var(--gray);
	border-radius: 6px;

	.search {
		max-height: 26px;
	}

	.buttonsContainer {
		display: flex;
		column-gap: 12px;
		padding: 8px;
	}
}

.filteredText {
	color: var(--text);
	overflow: hidden;
}
