@import 'notificationStyles.scss';
@import 'tableStyles.scss';

html,
body,
#root,
.app {
	position: relative;
	height: 100%;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'Manrope', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 130%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
ol {
	margin: 0;
	padding: 0;
}

.anticon anticon-loading anticon-spin > svg {
	color: var(--svg-white);
}

// scrollbar
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: transparent;

	&-track {
		background-color: transparent;
		border-radius: 5px;
	}

	&-thumb {
		border-radius: 5px;
	}

	&-corner {
		background-color: #ffffff;
	}
}

.ant-menu-inline-collapsed-tooltip {
	display: none;
}

// Стили для ошибки в полях
.ant-form-item-explain-error {
	margin-bottom: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}
