.chip {
	display: flex;
	align-items: center;
	gap: 8px !important;
	border: none;
	border-radius: 30px;
	color: var(--text) !important;
	padding-inline: 9px;
	padding-block: 4px;
	max-width: fit-content;
	font-weight: 500;
	margin: 0;
	font-size: 12px;
}

.small {
	padding-block: unset;
}

.selectChip {
	display: flex;
	align-items: center;
	max-height: 22px;
	gap: 4px !important;
	padding: 3px 8px;
	text-decoration: underline;
	margin: 0;
}

.clearBg {
	padding-inline: 0;
	padding-block: 0;
}
