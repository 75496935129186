.columnTree,
.columnAttributes {
	max-height: 50vh;
	overflow: scroll;
    width: 50%;
    padding-right: 10px;

	// scrollbar
	&::-webkit-scrollbar {
		width: 4px;
		height: 8px;
		background-color: transparent;

		&-track {
			background-color: transparent;
			border-radius: 5px;
		}

		&-thumb {
			border-radius: 5px;
            background-color: var(--bg-subsider-hover);

            &:hover {
                background-color: var(--grayTableBorder);
            }
		}

		&-corner {
			background-color: #ffffff;
		}
	}
}
