.container {
	display: grid;
	grid-template-columns: 1fr min-content;
	background: var(--light-gray);
	border-color: transparent !important;
	border-radius: 6px;
	padding: 7px 10px;
	justify-content: center;
	color: var(--text);
	height: 36px;
}

.input {
	display: flex;
	overflow: hidden;
	align-items: center;
	column-gap: 4px;
	flex-grow: 1;
}

.controls {
	cursor: pointer;
}

.icon {
	cursor: pointer;
}
