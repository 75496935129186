.ant-notification-notice {
	border-radius: 6px;
	padding: 12px 16px !important;
}

.ant-notification-notice-error {
	background-color: rgba(243, 88, 77, 0.12) !important;

	.ant-notification-notice-icon-error {
		background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(243, 88, 77, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>');
		width: 16px;
		height: 16px;
		color: var(--error);
	}

	.ant-notification-notice-icon-error > svg {
		display: none;
	}

	.ant-notification-notice-close-icon > svg {
		color: var(--gray-icon) !important;
	}
}

.ant-notification-notice-close {
	top: 9px !important;
	inset-inline-end: 10px !important;
}

.ant-notification-notice-success {
	svg {
		color: var(--success);
	}
}

.ant-notification-notice-message {
	font-size: 12px !important;
	font-weight: 700;
	margin-bottom: 4px !important;
}

.ant-notification-notice-description {
	font-size: 12px;
	font-weight: 500;
}

.ant-notification-notice-wrapper {
	box-shadow: none !important;
}
