.placeholderText {
	font-family: Manrope;
	font-size: 21px;
	font-weight: 700;
	text-align: center;
	color: var(--text-faded);
}

.smallText {
	font-size: 12px;
}
