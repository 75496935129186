.ant-tree-node-content-wrapper,
.ant-tree-treenode {
	width: 100%;

	svg:not(.ant-tree-switcher) {
		display: none;
	}

	&:hover {
		svg {
			display: block;
		}
	}

	.ant-tree-switcher {
		svg {
			display: block;
		}
	}
}

.ant-tree-treenode-selected {
	svg {
		display: block;
	}
}

.ant-tree-title {
	> svg {
		display: none;
	}

	&:hover {
		> svg {
			display: block;
		}
	}
}
