.subSider {
	position: fixed;
	top: 56px;
	margin-left: -24px;
	padding-right: 9px;
	width: 340px;
	height: calc(100% - 55px);
	transition: width 0.3s ease;
	overflow: hidden;
}

.open {
	margin-left: -340px;
}

.collapsed {
	width: 22px;
}

.subSiderButton {
	border: 1px solid rgba(21, 29, 52, 0.24);
	position: absolute;
	right: 0px;
	top: 19px;
	z-index: 2;
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;

	& :hover .subSiderButtonArrow {
		color: var(--primary);
	}
}

.subSiderContent {
	position: absolute;
	right: 9px;
	border-right: 1px solid rgba(21, 29, 52, 0.24);
	background: var(--whiteBase);
	padding: 48px 15px 12px 12px;
	height: 100%;
	width: 340px;
	overflow: auto;
}
