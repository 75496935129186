.icon {
	width: 16px;
	height: 16px;
}

.placeholder {
	background-color: var(--whiteBase);
	padding: 8px 16px 8px 8px;
	border: 1px solid var(--primaryBorderFaded) !important;
	::placeholder {
		color: var(--primary);
	}

	&:disabled {
		::placeholder {
			color: var(--gray);
		}

		svg {
			color: var(--gray);
		}
	}

	&:hover {
		background-color: #fff;
		border-color: var(--primary) !important;
	}

	&:focus-within {
		border-color: var(--primary) !important;
		box-shadow: unset;
	}
}
