.container .item:first-child {
	width: min-content;
}

.container .item:first-child {
	padding-top: 0;
	background-image: url(../../../shared/assets/whiteOrangePole.svg);
}

.container .item:last-child {
	padding-bottom: 0;
	background-image: url(../../../shared/assets/orangeWhitePole.svg);
}

.item {
	width: min-content;
	padding: 4px 0px 4px 1px;
	background-image: url(../../../shared/assets/orangePole.svg);
	background-repeat: no-repeat;
	background-position: left center;
	position: relative;
}

.item::after {
	position: absolute;
	content: url(../../../shared/assets/historyDot.svg);
	width: 16px;
	height: 16px;
	left: -8px;
	top: calc(50% - 8px);
}
