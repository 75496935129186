.routeStepCollapseItem {
  border-radius: 6px !important;
  border: 1px solid #E0E0E0 !important;

  & > .ant-collapse-header {
    align-items: center !important;
    gap: 12px;

    & > .ant-collapse-expand-icon {
      order: 1;
      padding-inline-end: unset !important;
      margin-left: 30px;
    }

    & > .ant-collapse-header-text {
      order: 2;
    }

    & > .ant-collapse-extra {
      order: 3;
    }
  }
}