.ant-modal-content {
	font-weight: 500 !important;
}

.ant-modal-header {
	margin-bottom: 32px !important;
}

.ant-modal-title {
	font-weight: 700 !important;
}

.ant-modal-mask {
	z-index: 1050;
}
